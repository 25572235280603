import { get, set } from 'idb-keyval'
import { acceptHMRUpdate, defineStore } from 'pinia'

interface PolicyIssue {
  policy: {
    id: string
    name: string
  }
  message: string
  url: string
}

interface ContentValidationState {
  initializing: boolean
  initialized: boolean
  errors: PolicyIssue[]
  warnings: PolicyIssue[]
}

export const useContentValidation = defineStore('content-validation', {
  state: (): ContentValidationState => {
    return {
      initializing: false,
      initialized: false,
      warnings: [],
      errors: [],
    }
  },

  getters: {
    errorCount: (state) => state.errors.length,
    errorsByPolicy: (state) => (policyId: string) =>
      state.errors.filter((error) => error.policy_id === policyId),
    warningCount: (state) => state.warnings.length,
    warningsByPolicy: (state) => (policyId: string) =>
      state.warnings.filter((warning) => warning.policy_id === policyId),
  },

  actions: {
    async fetchHealth() {
      const apiClient = useApiClient({ baseURL: '/api' })

      const health = await apiClient.fetch<ContentValidationState>('/integrity')

      this.$patch({
        ...health,
        initialized: true,
        initializing: false,
      })

      await set('health', health)

      return health
    },

    init() {
      this.loadHealth()
      if (this.initializing || this.initialized) {
        return
      }
      this.initializing = true

      return this.fetchHealth()
    },

    async loadHealth() {
      const health = await get<{
        errors: PolicyIssue[]
        warnings: PolicyIssue[]
      }>('health')

      if (health) {
        this.$patch({
          ...health,
        })
        return health
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useContentValidation, import.meta.hot))
